import React, { useState } from "react";
import SEO from "../components/SEO";
import useFirebase from "../../firebase/useFirebase";
import {
  CustomButton,
  CustomButtonContainer,
  PageSection,
  PageSectionBackdrop,
  PageSectionWrapper,
  TextInput,
} from "../components/common";
import {
  LoginInputWrapper,
  LoginArticle,
  LoginArticleForm,
  LoginArticleFormsContainer,
  LoginArticleNoShow,
  LoginInfo,
} from "../components/Login";
import { PageHeaderImageSign } from "../components/common/PageHeaderImage";

const EMAIL_STATUSES = {
  NOT_SENT: "NOT_SENT",
  SENDING: "SENDING",
  SENT: "SENT",
  FAILED: "FAILED",
};

const Confirmation = () => {
  const firebase = useFirebase();
  const [status, setStatus] = useState(EMAIL_STATUSES.NOT_SENT);
  const [email, setEmail] = useState("");

  const sendResetPasswordEmail = () =>
    firebase.auth().sendPasswordResetEmail(email, {
      url: `${window.location.origin}/login`,
      handleCodeInApp: true,
    });

  const handleOnChange = ({ target }) => {
    setEmail(target.value);
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setStatus(EMAIL_STATUSES.SENDING);
      await sendResetPasswordEmail();
      setStatus(EMAIL_STATUSES.SENT);
    } catch (e) {
      console.log({ e });
      setStatus(EMAIL_STATUSES.FAILED);
    }
  };

  return (
    <>
      <SEO title="Återställ lösenord" />

      <PageSectionWrapper>
        <PageSection bgColor="var(--loginBg)">
          <PageSectionBackdrop bgColor="var(--loginBg)">
            <LoginArticleNoShow>
              <h1>
                Använd en enhet med större skärm för att logga in och använda
                inloggat läge
              </h1>
            </LoginArticleNoShow>
            <LoginArticle>
              <LoginArticleFormsContainer>
                <PageHeaderImageSign />
                <h1>Återställ ditt lösenord</h1>
                <LoginArticleForm onSubmit={onSubmit} marginBottom="20px">
                  <LoginInputWrapper>
                    <label htmlFor="email">E-post:</label>
                    <TextInput
                      type="email"
                      name="email"
                      id="email"
                      onChange={handleOnChange}
                      customWidth="325px"
                    />
                  </LoginInputWrapper>
                  <CustomButtonContainer width="325px">
                    <CustomButton
                      type="submit"
                      disabled={status === EMAIL_STATUSES.SENDING}
                    >
                      Återställ lösenord
                    </CustomButton>
                  </CustomButtonContainer>
                </LoginArticleForm>
                {status === EMAIL_STATUSES.SENT && (
                  <LoginInfo>
                    <p>E-post har skickats till {email}</p>
                  </LoginInfo>
                )}

                {status === EMAIL_STATUSES.FAILED && (
                  <LoginInfo>
                    <p>
                      Något gick fel när vi försökte återställa lösenordet,
                      försök igen
                    </p>
                  </LoginInfo>
                )}
              </LoginArticleFormsContainer>
            </LoginArticle>
          </PageSectionBackdrop>
        </PageSection>
      </PageSectionWrapper>
    </>
  );
};

export default Confirmation;
